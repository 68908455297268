import btc from '../static/coins/bitcoin-btc-logo.svg';
import ltc from '../static/coins/litecoin-ltc-logo.svg';
import eth from '../static/coins/ethereum-eth-logo.svg';
import sol from '../static/coins/solana-sol-logo.svg';
import doge from '../static/coins/dogecoin-doge-logo.svg';
import usdc from '../static/coins/usd-coin-usdc-logo.svg';
import usdt from '../static/coins/tether-usdt-logo.svg';
import bnb from '../static/coins/bnb-bnb-logo.svg';

export const Currency = {
    'BTC': 'BTC',
    'LTC': 'LTC',
    'ETH': 'ETH',
    'DOGE': 'DOGE',
    'SOL': 'SOL',
    'USDT': 'USDT',
    'USDC': 'USDC',
    'BNB': 'BNB'
 }

export const CurrencyData = {
  [Currency.BTC]: { name: 'Bitcoin', icon: btc },
  [Currency.LTC]: { name: 'Litecoin', icon: ltc },
  [Currency.ETH]: { name: 'Ethereum', icon: eth },
  [Currency.DOGE]: { name: 'Dogecoin', icon: doge },
  [Currency.SOL]: { name: 'Solana', icon: sol},
  [Currency.BNB]: { name: 'Binance', icon: bnb},
  [Currency.USDC]: { name: 'USD Coin', icon: usdc},
  [Currency.USDT]: { name: 'Tether', icon: usdt},
}
  
export const MNEMONIC_SIZE_PER_PAGE = 10;