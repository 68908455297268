/* eslint react/prop-types: 0 */
import React, { cloneElement, isValidElement } from 'react';
import classNames from 'classnames';
import Pager from './Pager';
import Options from './Options';
import KEYCODE from './KeyCode';
import { isInteger } from './isInteger';
import './styles.css';
import { BN } from 'bn.js';

function noop() {}

function defaultItemRender(page, type, element) {
  return element;
}

/**
 *
 * @param {string} ps
 * @param {PaginationState} state
 * @param {PaginationProps} props
 * @returns {BN}
 */
function calculatePage(ps, state, props) {
  const pageSize = typeof ps === 'undefined' ? state.pageSize : ps;
  const total_bn = new BN(props.total);
  const pageSize_bn = new BN(pageSize);

  const page = total_bn.sub(new BN(1)).divRound(pageSize_bn);

  return page;
  // return  Math.floor((props.total - 1) / pageSize) + 1;
}

class Pagination extends React.Component {

  static defaultProps = {
    defaultCurrent: '1',
    total: '0',
    defaultPageSize: '10',
    onChange: noop,
    className: '',
    selectPrefixCls: 'rc-select',
    prefixCls: 'rc-pagination',
    selectComponentClass: null,
    hideOnSinglePage: false,
    showPrevNextJumpers: true,
    showQuickJumper: false,
    showLessItems: false,
    showTitle: true,
    onShowSizeChange: noop,
    style: {},
    itemRender: defaultItemRender,
    totalBoundaryShowSizeChanger: '50',
  };

  constructor(props) {
    super(props);

    const hasOnChange = props.onChange !== noop;
    const hasCurrent = 'current' in props;
    if (hasCurrent && !hasOnChange) {
      // eslint-disable-next-line no-console
      console.warn(
        'Warning: You provided a `current` prop to a Pagination component without an `onChange` handler. This will render a read-only component.',
      );
    }

    let current = props.defaultCurrent;
    if ('current' in props) {
      // eslint-disable-next-line prefer-destructuring
      current = props.current;
    }

    let pageSize = props.defaultPageSize;
    if ('pageSize' in props) {
      // eslint-disable-next-line prefer-destructuring
      pageSize = props.pageSize;
    }

    const current_bn = BN.min(new BN(current), calculatePage(pageSize, undefined, props));

    this.state = {
      current: current_bn.toString(),
      currentInputValue: current,
      pageSize: pageSize,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // When current page change, fix focused style of prev item
    // A hacky solution of https://github.com/ant-design/ant-design/issues/8948
    const { prefixCls } = this.props;
    if (prevState.current !== this.state.current && this.paginationNode) {
      const lastCurrentNode = this.paginationNode.querySelector(
        `.${prefixCls}-item-${prevState.current}`,
      );
      if (lastCurrentNode && document.activeElement === lastCurrentNode) {
        lastCurrentNode.blur();
      }
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    const newState = {};

    if ('current' in props) {
      newState.current = props.current;

      if (props.current !== prevState.current) {
        newState.currentInputValue = newState.current;
      }
    }

    if ('pageSize' in props && props.pageSize !== prevState.pageSize) {
      const { current } = prevState;
      const current_bn = new BN(current);
      const newCurrent_bn = calculatePage(props.pageSize, prevState, props);
      const resultedCurrent_bn = current_bn.gt(newCurrent_bn) ? newCurrent_bn : current_bn;

      if (!('current' in props)) {
        newState.current = resultedCurrent_bn.toString();
        newState.currentInputValue = resultedCurrent_bn.toString();
      }
      newState.pageSize = props.pageSize;
    }

    return newState;
  }

  getJumpPrevPage = () =>
    BN.max(new BN(1), new BN(this.state.current).sub(new BN(this.props.showLessItems ? 3 : 5)));

  getJumpNextPage = () =>
    BN.min(
      calculatePage(undefined, this.state, this.props),
      new BN(this.state.current).add(new BN(this.props.showLessItems ? 3 : 5))
    );

  /**
   * computed icon node that need to be rendered.
   * @param {React.ReactNode | React.ComponentType<PaginationProps>} icon received icon.
   * @returns {React.ReactNode}
   */
  getItemIcon = (icon, label) => {
    const { prefixCls } = this.props;
    let iconNode = icon || (
      <button
        type="button"
        aria-label={label}
        className={`${prefixCls}-item-link`}
      />
    );
    if (typeof icon === 'function') {
      iconNode = React.createElement(icon, { ...this.props });
    }
    return iconNode;
  };

  getValidValue(e) {
    const inputValue = e.target.value;
    const allPages = calculatePage(undefined, this.state, this.props);
    const { currentInputValue } = this.state;
    let value;
    if (inputValue === '') {
      value = inputValue;
      // eslint-disable-next-line no-restricted-globals
    } else if (!isInteger(inputValue)) {
      value = currentInputValue;
    } else if (new BN(inputValue).gte(allPages)) {
      value = allPages.toString();
    } else {
      value = inputValue;
    }
    return value;
  }

  savePaginationNode = (node) => {
    this.paginationNode = node;
  };

  /**
   *
   * @param {string} page
   * @returns {boolean}
   */
  isValid = (page) => {
    const { total } = this.props;
    const isInt = isInteger(page);
    const isNotSamePage = page !== this.state.current;
    const isTotalInt = isInteger(total);
    const isPositiveTotal = new BN(total).gt(new BN(0));
    return isInt && isNotSamePage && isTotalInt && isPositiveTotal;
  };

  shouldDisplayQuickJumper = () => {
    const { showQuickJumper, total } = this.props;
    const { pageSize } = this.state;
    if (new BN(total).lte(new BN(pageSize))) {
      return false;
    }
    return showQuickJumper;
  };

  handleKeyDown = (e) => {
    if (e.keyCode === KEYCODE.ARROW_UP || e.keyCode === KEYCODE.ARROW_DOWN) {
      e.preventDefault();
    }
  };

  handleKeyUp = (e) => {
    const value = this.getValidValue(e);
    const { currentInputValue } = this.state;
    if (value !== currentInputValue) {
      this.setState({
        currentInputValue: value,
      });
    }
    if (e.keyCode === KEYCODE.ENTER) {
      this.handleChange(value);
    } else if (e.keyCode === KEYCODE.ARROW_UP) {
      this.handleChange((new BN(value)).sub(new BN(1)).toString());
    } else if (e.keyCode === KEYCODE.ARROW_DOWN) {
      this.handleChange((new BN(value)).add(new BN(1)).toString());
    }
  };

  handleBlur = (e) => {
    const value = this.getValidValue(e);
    this.handleChange(value);
  };

  /**
   *
   * @param {string} size
   */
  changePageSize = (size) => {
    const current_bn = new BN(this.state.current);
    const newCurrent_bn = calculatePage(size, this.state, this.props);
    let largestCurrent_bn = current_bn.gt(newCurrent_bn) ? newCurrent_bn : current_bn;

    // fix the issue:
    // Once 'total' is 0, 'current' in 'onShowSizeChange' is 0, which is not correct.
    if (newCurrent_bn.eq(new BN(0))) {
      // eslint-disable-next-line prefer-destructuring
      largestCurrent_bn = new BN(this.state.current);
    }

    if (typeof size === 'string') {
      if (!('pageSize' in this.props)) {
        this.setState({
          pageSize: size,
        });
      }
      if (!('current' in this.props)) {
        this.setState({
          current: largestCurrent_bn.toString(),
          currentInputValue: largestCurrent_bn.toString(),
        });
      }
    }

    this.props.onShowSizeChange(largestCurrent_bn.toString(), size);

    if ('onChange' in this.props && this.props.onChange) {
      this.props.onChange(largestCurrent_bn.toString(), size);
    }
  };

  /**
   *
   * @param {string} page
   * @returns {string} currentPage
   */
  handleChange = (page) => {
    const { disabled, onChange } = this.props;
    const { pageSize, current, currentInputValue } = this.state;

    if (this.isValid(page) && !disabled) {
      const currentPage_bn = calculatePage(undefined, this.state, this.props);
      let newPage_bn = new BN(page);
      if (newPage_bn.gt(currentPage_bn)) {
        newPage_bn = currentPage_bn;
      } else if (new BN(page).lt(new BN(1))) {
        newPage_bn = new BN(1);
      }
      if (!('current' in this.props)) {
        this.setState({
          current: newPage_bn.toString(),
        });
      }
      if (newPage_bn.toString() !== currentInputValue) {
        this.setState({
          currentInputValue: newPage_bn.toString(),
        });
      }
      onChange(newPage_bn.toString(), pageSize);
      return newPage_bn.toString();
    }
    return current;
  };

  prev = () => {
    if (this.hasPrev()) {
      this.handleChange(new BN(this.state.current).sub(new BN(1)));
    }
  };

  next = () => {
    if (this.hasNext()) {
      this.handleChange(new BN(this.state.current).add(new BN(1)));
    }
  };

  jumpPrev = () => {
    this.handleChange(this.getJumpPrevPage());
  };

  jumpNext = () => {
    this.handleChange(this.getJumpNextPage());
  };

  hasPrev = () => new BN(this.state.current).gt(new BN(1));

  hasNext = () => new BN(this.state.current).lt(calculatePage(undefined, this.state, this.props));

  getShowSizeChanger() {
    const { showSizeChanger, total, totalBoundaryShowSizeChanger } = this.props;
    if (typeof showSizeChanger !== 'undefined') {
      return showSizeChanger;
    }
    return new BN(total).gt(new BN(totalBoundaryShowSizeChanger));
  }

  runIfEnter = (event, callback, ...restParams) => {
    if (event.key === 'Enter' || event.charCode === 13) {
      callback(...restParams);
    }
  };

  runIfEnterPrev = (e) => {
    this.runIfEnter(e, this.prev);
  };

  runIfEnterNext = (e) => {
    this.runIfEnter(e, this.next);
  };

  runIfEnterJumpPrev = (e) => {
    this.runIfEnter(e, this.jumpPrev);
  };

  runIfEnterJumpNext = (e) => {
    this.runIfEnter(e, this.jumpNext);
  };

  handleGoTO = (e) => {
    if (e.keyCode === KEYCODE.ENTER || e.type === 'click') {
      this.handleChange(this.state.currentInputValue);
    }
  };

  /**
   *
   * @param {string} prevPage
   * @returns
   */
  renderPrev(prevPage) {
    const { prevIcon, itemRender } = this.props;
    const prevButton = itemRender(
      prevPage,
      'prev',
      this.getItemIcon(prevIcon, 'prev page'),
    );
    const disabled = !this.hasPrev();
    return isValidElement(prevButton)
      ? cloneElement(prevButton, { disabled })
      : prevButton;
  }

  /**
   *
   * @param {string} nextPage
   * @returns
   */
  renderNext(nextPage) {
    const { nextIcon, itemRender } = this.props;
    const nextButton = itemRender(
      nextPage,
      'next',
      this.getItemIcon(nextIcon, 'next page'),
    );
    const disabled = !this.hasNext();
    return isValidElement(nextButton)
      ? cloneElement(nextButton, { disabled })
      : nextButton;
  }

  render() {
    const {
      prefixCls,
      className,
      style,
      disabled,
      hideOnSinglePage,
      total,
      showQuickJumper,
      showLessItems,
      showTitle,
      showTotal,
      simple,
      itemRender,
      showPrevNextJumpers,
      jumpPrevIcon,
      jumpNextIcon,
      selectComponentClass,
      selectPrefixCls,
      pageSizeOptions,
    } = this.props;

    const { current, pageSize, currentInputValue } = this.state;

    // When hideOnSinglePage is true and there is only 1 page, hide the pager
    if (hideOnSinglePage === true && total <= pageSize) {
      return null;
    }

    const allPages = calculatePage(undefined, this.state, this.props);
    const pagerList = [];
    let jumpPrev = null;
    let jumpNext = null;
    let firstPager = null;
    let lastPager = null;
    let gotoButton = null;

    const goButton = showQuickJumper && showQuickJumper.goButton;
    const pageBufferSize = showLessItems ? 1 : 2;

    const prevPage = new BN(current).sub(new BN(1)).gt(new BN(0)) ? new BN(current).sub(new BN(1)) : new BN(0);
    const nextPage = new BN(current).add(new BN(1)).lt(new BN(allPages)) ? new BN(current).add(new BN(1)) : new BN(allPages);

    const dataOrAriaAttributeProps = Object.keys(this.props).reduce(
      (prev, key) => {
        if (
          key.substr(0, 5) === 'data-' ||
          key.substr(0, 5) === 'aria-' ||
          key === 'role'
        ) {
          // eslint-disable-next-line no-param-reassign
          prev[key] = this.props[key];
        }
        return prev;
      },
      {},
    );

    if (simple) {
      if (goButton) {
        if (typeof goButton === 'boolean') {
          gotoButton = (
            <button
              type="button"
              onClick={this.handleGoTO}
              onKeyUp={this.handleGoTO}
            >
              confirm
            </button>
          );
        } else {
          gotoButton = (
            <span onClick={this.handleGoTO} onKeyUp={this.handleGoTO}>
              {goButton}
            </span>
          );
        }
        gotoButton = (
          <li
            title={showTitle ? `Go to ${current}/${allPages}` : null}
            className={`${prefixCls}-simple-pager`}
          >
            {gotoButton}
          </li>
        );
      }

      return (
        <ul
          className={classNames(
            prefixCls,
            `${prefixCls}-simple`,
            { [`${prefixCls}-disabled`]: disabled },
            className,
          )}
          style={style}
          ref={this.savePaginationNode}
          {...dataOrAriaAttributeProps}
        >
          <li
            title={showTitle ? 'Previous Page' : null}
            onClick={this.prev}
            tabIndex={this.hasPrev() ? 0 : null}
            onKeyPress={this.runIfEnterPrev}
            className={classNames(`${prefixCls}-prev`, {
              [`${prefixCls}-disabled`]: !this.hasPrev(),
            })}
            aria-disabled={!this.hasPrev()}
          >
            {this.renderPrev(prevPage)}
          </li>
          <li
            title={showTitle ? `${current}/${allPages}` : null}
            className={`${prefixCls}-simple-pager`}
          >
            <input
              type="text"
              value={currentInputValue}
              disabled={disabled}
              onKeyDown={this.handleKeyDown}
              onKeyUp={this.handleKeyUp}
              onChange={this.handleKeyUp}
              onBlur={this.handleBlur}
              size={3}
            />
            <span className={`${prefixCls}-slash`}>/</span>
            {allPages}
          </li>
          <li
            title={showTitle ? 'Next Page' : null}
            onClick={this.next}
            tabIndex={this.hasPrev() ? 0 : null}
            onKeyPress={this.runIfEnterNext}
            className={classNames(`${prefixCls}-next`, {
              [`${prefixCls}-disabled`]: !this.hasNext(),
            })}
            aria-disabled={!this.hasNext()}
          >
            {this.renderNext(nextPage)}
          </li>
          {gotoButton}
        </ul>
      );
    }

    /* allPages <= 3 + pageBufferSize * 2 */
    if (allPages.lte(new BN(3).add(new BN(pageBufferSize).mul(new BN(2))))) {
      const pagerProps = {
        rootPrefixCls: prefixCls,
        onClick: this.handleChange,
        onKeyPress: this.runIfEnter,
        showTitle,
        itemRender,
      };
      if (!allPages) {
        pagerList.push(
          <Pager
            {...pagerProps}
            key="noPager"
            page='1'
            className={`${prefixCls}-item-disabled`}
          />,
        );
      }
      // малое число, bn не нужен
      for (let i = 1; i <= allPages.toNumber(); i += 1) {
        const active = current === i.toString();
        pagerList.push(
          <Pager {...pagerProps} key={i} page={i.toString()} active={active} />,
        );
      }
    } else {
      const prevItemTitle = showLessItems ? 'Previous 3 Pages' : 'Previous 5 Pages';
      const nextItemTitle = showLessItems ? 'Next 3 Pages' : 'Next 5 Pages';
      if (showPrevNextJumpers) {
        jumpPrev = (
          <li
            title={showTitle ? prevItemTitle : null}
            key="prev"
            onClick={this.jumpPrev}
            tabIndex={0}
            onKeyPress={this.runIfEnterJumpPrev}
            className={classNames(`${prefixCls}-jump-prev`, {
              [`${prefixCls}-jump-prev-custom-icon`]: Boolean(jumpPrevIcon),
            })}
          >
            {itemRender(
              this.getJumpPrevPage(),
              'jump-prev',
              this.getItemIcon(jumpPrevIcon, 'prev page'),
            )}
          </li>
        );
        jumpNext = (
          <li
            title={showTitle ? nextItemTitle : null}
            key="next"
            tabIndex={0}
            onClick={this.jumpNext}
            onKeyPress={this.runIfEnterJumpNext}
            className={classNames(`${prefixCls}-jump-next`, {
              [`${prefixCls}-jump-next-custom-icon`]: Boolean(jumpNextIcon),
            })}
          >
            {itemRender(
              this.getJumpNextPage(),
              'jump-next',
              this.getItemIcon(jumpNextIcon, 'next page'),
            )}
          </li>
        );
      }
      lastPager = (
        <Pager
          last
          rootPrefixCls={prefixCls}
          onClick={this.handleChange}
          onKeyPress={this.runIfEnter}
          key={allPages.toString()}
          page={allPages.toString()}
          active={false}
          showTitle={showTitle}
          itemRender={itemRender}
        />
      );
      firstPager = (
        <Pager
          rootPrefixCls={prefixCls}
          onClick={this.handleChange}
          onKeyPress={this.runIfEnter}
          key={'1'}
          page={'1'}
          active={false}
          showTitle={showTitle}
          itemRender={itemRender}
        />
      );

      let left_bn = BN.max(new BN(1), new BN(current).sub(new BN(pageBufferSize)));
      let right_bn = BN.min(new BN(current).add(new BN(pageBufferSize)), new BN(allPages));

      if (new BN(current).sub(new BN(1)).lte(new BN(pageBufferSize))) {
        right_bn = new BN(1).add(new BN(pageBufferSize).mul(new BN(2)));
      }

      if (new BN(allPages).sub(new BN(current)).lte(new BN(pageBufferSize))) {
        left_bn = new BN(allPages).sub(new BN(pageBufferSize).mul(new BN(2)));
      }

      for (let i = left_bn; i.lte(right_bn); i = i.add(new BN(1))) {
        const active = current === i.toString();
        pagerList.push(
          <Pager
            rootPrefixCls={prefixCls}
            onClick={this.handleChange}
            onKeyPress={this.runIfEnter}
            key={i.toString()}
            page={i.toString()}
            active={active}
            showTitle={showTitle}
            itemRender={itemRender}
          />,
        );
      }

      if (new BN(current).sub(new BN(1)).gte(new BN(pageBufferSize).mul(new BN(2))) && current !== '3') {
        pagerList[0] = cloneElement(pagerList[0], {
          className: `${prefixCls}-item-after-jump-prev`,
        });
        pagerList.unshift(jumpPrev);
      }
      if (
        // allPages - current >= pageBufferSize * 2 &&
        // current !== allPages - 2
        new BN(allPages).sub(new BN(current)).gte(new BN(pageBufferSize).mul(new BN(2)))
      ) {
        pagerList[pagerList.length - 1] = cloneElement(
          pagerList[pagerList.length - 1],
          {
            className: `${prefixCls}-item-before-jump-next`,
          },
        );
        pagerList.push(jumpNext);
      }

      if (left_bn.toString() !== '1') {
        pagerList.unshift(firstPager);
      }
      if (right_bn.toString() !== allPages.toString()) {
        pagerList.push(lastPager);
      }
    }

    let totalText = null;

    if (showTotal) {
      totalText = (
        <li className={`${prefixCls}-total-text`}>
          {showTotal(total, [
            total === '0' ? '0' : (new BN(current).sub(new BN(1))).mul(new BN(pageSize)).add(new BN(1)),
            new BN(current).mul(new BN(pageSize)).gt(new BN(total)) ? total : new BN(current).mul(new BN(pageSize)).toString(),
          ])}
        </li>
      );
    }
    const prevDisabled = !this.hasPrev() || !allPages;
    const nextDisabled = !this.hasNext() || !allPages;
    return (
      <ul
        className={classNames(prefixCls, className, {
          [`${prefixCls}-disabled`]: disabled,
        })}
        style={style}
        unselectable="off"
        ref={this.savePaginationNode}
        {...dataOrAriaAttributeProps}
      >
        {totalText}
        <li
          title={showTitle ? 'Previous Page' : null}
          onClick={this.prev}
          tabIndex={prevDisabled ? null : 0}
          onKeyPress={this.runIfEnterPrev}
          className={classNames(`${prefixCls}-prev`, {
            [`${prefixCls}-disabled`]: prevDisabled,
          })}
          aria-disabled={prevDisabled}
        >
          {this.renderPrev(prevPage)}
        </li>
        {pagerList}
        <li
          title={showTitle ? 'Next Page' : null}
          onClick={this.next}
          tabIndex={nextDisabled ? null : 0}
          onKeyPress={this.runIfEnterNext}
          className={classNames(`${prefixCls}-next`, {
            [`${prefixCls}-disabled`]: nextDisabled,
          })}
          aria-disabled={nextDisabled}
        >
          {this.renderNext(nextPage)}
        </li>
        <Options
          disabled={disabled}
          rootPrefixCls={prefixCls}
          selectComponentClass={selectComponentClass}
          selectPrefixCls={selectPrefixCls}
          changeSize={this.getShowSizeChanger() ? this.changePageSize : null}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          quickGo={this.shouldDisplayQuickJumper() ? this.handleChange : null}
          goButton={goButton}
        />
      </ul>
    );
  }
}

export default Pagination;
