import { useState } from 'react'
import { Currency } from "../constants";

const walletListStub = [
    // { currency: Currency.BTC, address: '1cMh228HTCiwS8ZsaakH8A8wze1JR5ZsP', transactions: 0, balance: 0 },
    { currency: Currency.LTC, address: '3CDJNfdWX8m2NwuGUV3nhXHXEeLygMXoAj', totalReceived: 0, transactions: 0, balance: 0 },
    { currency: Currency.ETH, address: '0x71C7656EC7ab88b098defB751B7401B5f6d8976F', totalReceived: 0, transactions: 0, balance: 0 },
    { currency: Currency.DOGE, address: '1C7656EC7ab88b098defB751B765464f6d8976F', totalReceived: 43.55, transactions: 2, balance: 0.003 },
    { currency: Currency.SOL, address: '63345C7ab88b098defB751B745645f698976F', totalReceived: 0, transactions: 0, balance: 0 },
    { currency: Currency.BNB, address: '87687ab88b098defB751B745645f698976F', totalReceived: 1000, transactions: 4, balance: 200 },
    { currency: Currency.USDC, address: '4vrvv7ab88b098defB751B745645f698976F', totalReceived: 0, transactions: 0, balance: 0 },
    { currency: Currency.USDT, address: '4000000000098defB751B745645f698976F', totalReceived: 10, transactions: 1, balance: 10 },
]

  const getBitcoinAddressType = (address) => {
    if (address.startsWith('1')) {
        return 'Legacy';
    } else if (address.startsWith('bc1')) {
        return 'SegWit';
    }

    return 'P2SH';
  }

  const getNormalizedData = (btcResult) => {
      return Object.entries(btcResult).map(([address, data], i) => ({
          currency: Currency.BTC,
          address,
          transactions: data.n_tx,
          balance: data.final_balance,
          totalReceived: data.total_received,
          type: getBitcoinAddressType(address)
      })).concat(walletListStub);
  }

export const useWalletList = () => {
    const [walletList, setWalletList] = useState([]);

    const [isWalletListLoading, setIsWalletListLoading] = useState(false);
    const [walletListError, setWalletListError] = useState(null);

    const getWalletList = async entropy => {
        setIsWalletListLoading(true)

        try {
            const btcRes = await fetch(`https://mnemonia.xyz/api/mnemonic/${entropy}`);
            const btcData = await btcRes.json();

            setWalletList(getNormalizedData(btcData));
        } catch (e) {
            setWalletListError(e);
            setWalletList(walletListStub);
        } finally {
            setIsWalletListLoading(false);
        }
    }

    return {
        getWalletList,
        walletList,
        isWalletListLoading,
        walletListError,
        isWalletListError: Boolean(walletListError),
    }
}
