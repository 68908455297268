import React from 'react';
import cn from 'classnames'
import { Currency } from '../../constants';
import { Tooltip, Typography } from 'antd';
import s from './style.module.css';
import copyIconSrc from '../../static/copy.svg';
import linkIconSrc from '../../static/link.svg';

const renderNumberColumn = (value) => <span className={cn({ [s.zero]: value === 0 })}>{value}</span>

export const addressColumn = {
        title: 'Address',
        dataIndex: ['address', 'currency'],
        key: 'address',
        render: (_, {currency, address}) => <>
            <Typography.Text className={s.address} copyable={{ 
                text: address,
                icon: [
                    <img key="copy-icon" src={copyIconSrc} className={s.copyIcon} />, 
                    <img key="copy-icon" src={copyIconSrc} className={cn(s.copyIcon, s.done)} />
                ],
                }}>
                    {address}
                </Typography.Text>
                {
                    currency === Currency.BTC && (
                        <Tooltip placement="top" title='Explore'>
                            <a href={`https://www.blockchain.com/ru/btc/address/${address}`} target='_blank'>
                                <img key="copy-icon" src={linkIconSrc} className={s.linkIcon} />
                            </a>
                        </Tooltip>
                    )
                }
               
        </>
    };

export const transactionsColumn = {
        title: 'Transactions',
        dataIndex: 'transactions',
        key: 'transactions',
        width: 120,
        render: renderNumberColumn
    };

export const totalReceivedColumn = {
        title: 'Total received',
        dataIndex: 'totalReceived',
        key: 'totalReceived',
        width: 130,
        render: renderNumberColumn
    };

export const balanceColumn = {
        title: 'Balance',
        dataIndex: ['balance', 'currency'],
        key: 'balance',
        render: (_, record) =>  <span className={cn({ [s.zero]: record.balance === 0 })}>
            {record.balance} {record.currency}
        </span>,
        width: 150,
      };