import { useState } from 'react'
import { Currency } from "../constants";
import { btcStub } from '../stub';
import { entropyToMnemonic } from 'bip39';
import { generateEntropyPage } from '../utils/script';
import BN from 'bn.js';

const getNormalizedData = (btcResult, mnemonicList) => {
    return btcResult.map((result, i) => {
        const keys = Object.keys(result);
        const address = keys[0];
        const data = result[address];

        return ({
          mnemonic: mnemonicList[i],
          currency: Currency.BTC,
          address,
          transactions: data.n_tx,
          balance: data.final_balance,
          totalReceived: data.total_received,
    })});
}

/**
 *
 * @param {BN} offset
 * @param {BN} size
 * @returns
 */
const generateMnemonicList = (offset, size) => {
    const entropyList = generateEntropyPage(offset, size);

    return entropyList.map(entropy => entropyToMnemonic(entropy));
}

export const useMnemonicList = () => {
    const [mnemonicList, setMnemonicList] = useState([]);

    const [isMnemonicListLoading, setIsMnemonicListLoading] = useState(false);
    const [mnemonicListError, setMnemonicListError] = useState(null);

    const getMnemonicList = async ({ page, size, entropy, currency }) => {
        setIsMnemonicListLoading(true);

        const offset = (new BN(page).sub(new BN(1))).mul(new BN(size));

        try {
            const list = generateMnemonicList(offset, new BN(size));
            const btcRes = await fetch(`https://mnemonia.xyz/api/mnemonic?offset=${offset}&size=${size}&currency=btc`);
            const btcData = await btcRes.json();
            const normalized = getNormalizedData(btcData, list);

            setMnemonicList(normalized);
        } catch (e) {
            setMnemonicListError(e);
            setMnemonicList(btcStub);
        } finally {
            setIsMnemonicListLoading(false);
        }
    }

    return {
        getMnemonicList,
        mnemonicList,
        isMnemonicListLoading,
        mnemonicListError,
        isMnemonicListError: Boolean(mnemonicListError),
    }
}
