import { useEffect, useState, useRef } from 'react';
import { AutoComplete, Input } from 'antd';
import { wordlists, validateMnemonic } from 'bip39';
import styles from './style.module.css';

const englishWords = wordlists.english;

// имеет смысл мемоизировать
export function getMnemonicWithReplacedWord(words, wordToReplace, index) {
    return words
    .map((currentWord, i) => i === index ? wordToReplace : currentWord)
    .join(' ')
}

let newWords = '';

export const MnemonicLookup = ({ words, index, onChange }) => {
    const [options, setOptions] = useState([]);
    const [shownOptions, setShownOptions] = useState(options);

    const [inputValue, setInputValue] = useState(words[index]);
    const [isValidWord, setIsValidWord] = useState(true);

    const elementRef = useRef();

    useEffect(() => {
        setInputValue(words[index])
    }, [words])

    const getAllValidOptions = () => {
        setOptions(englishWords.filter(wordToReplace => {

            newWords = getMnemonicWithReplacedWord(words, wordToReplace, index)

            return validateMnemonic(newWords);
         }).map(value => ({ value })))
    }

    useEffect(() => {
        setShownOptions(options)
    }, [options])

    const submitWord = (newWord) => {
        onChange(newWord, index)
    }

    const handleInputChange = (value) => {
        setInputValue(value);
        setShownOptions(options.filter((option) => option.value?.startsWith(value)))

        newWords = getMnemonicWithReplacedWord(words, value, index)

        const isValidMnemonic = validateMnemonic(newWords);
        if (isValidMnemonic) {
            setIsValidWord(true);
            elementRef.current.blur();
            submitWord(value);
        } else {
            setIsValidWord(false);
        }
    }

    return <AutoComplete
        value={inputValue}
        onChange={handleInputChange}
        onSelect={submitWord}
        options={shownOptions}
        placeholder="Enter a word"
        onFocus={getAllValidOptions}
        onPaste={(e) => {
            e.preventDefault()
            return false
        }}
        className={styles.autoComplete}
        ref={elementRef}
    >
        <Input style={{ borderColor: isValidWord ? null : 'red', borderRadius: '3px' }} />
    </AutoComplete>
}
