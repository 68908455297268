import { Currency } from "./constants";

export const btcStub = [
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abandon abandon apple apple abandon abandon abandon abandon about', 
        address: '3CDJNfdWX8m2NwuGUV3nhXHXEeLygMXoAj', 
        totalReceived: 25.86,
        transactions: 3,
        balance: 0.002 
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abandon abandon abandon apple abandon abandon abandon about about', 
        address: '1C7656EC7ab88b098defB751B765464f6d8976F', 
        totalReceived: 0,
        transactions: 34,
        balance: 0.00001
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abandon abandon abandon abandon abandon abandon arrow about about', 
        address: '1cMh228HTCiwS8ZsaakH8A8wze1JR5ZsP', 
        totalReceived: 0,
        transactions: 0,
        balance: 0
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abandon abandon abandon abandon abandon abandon abandon abandon about', 
        address: '3CDJNfdWX8m2NwuGUV3nhXHXEeLygMXoAj', 
        totalReceived: 25.86,
        transactions: 3,
        balance: 0.002 
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abandon abandon abandon abandon abandon abandon abandon about about', 
        address: '1C7656EC7ab88b098defB751B765464f6d8976F', 
        totalReceived: 0,
        transactions: 34,
        balance: 0.00001
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abort abandon abandon abandon abandon abandon arrow about about', 
        address: '1cMh228HTCiwS8ZsaakH8A8wze1JR5ZsP', 
        totalReceived: 0,
        transactions: 0,
        balance: 0
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abandon abandon about abandon abandon abandon abandon abandon about', 
        address: '3CDJNfdWX8m2NwuGUV3nhXHXEeLygMXoAj', 
        totalReceived: 25.86,
        transactions: 3,
        balance: 0.002 
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon about abandon abandon abandon abandon abandon abandon abandon about about', 
        address: '1C7656EC7ab88b0982efB751B765464f6d8976F', 
        totalReceived: 0,
        transactions: 34,
        balance: 0.00001
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abandon abandon abandon about abandon abandon arrow about about', 
        address: '1cMh228HTCiwS8ZsaakH8A8wz45JR5ZsP', 
        totalReceived: 0,
        transactions: 0,
        balance: 0
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon about abandon abandon abandon abandon abandon abandon abandon abandon abandon about', 
        address: '3CDJNfdWX8m2NwuGUV3nhXHXE8LygMXoAj', 
        totalReceived: 1.3336,
        transactions: 3,
        balance: 0.002 
    },
    { 
        currency: Currency.BTC,
        mnemonic: 
        'abandon abandon abandon arrow abandon abandon abandon abandon abandon abandon about about', 
        address: '1C7656EC7ab88b098defB741B765464f6d8976F', 
        totalReceived: 0,
        transactions: 34,
        balance: 0.00001
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'about abandon abandon abandon abandon abandon abandon abandon abandon arrow about about', 
        address: '1cMh228HTCiwS8ZsaakH8A8wze1JR578P', 
        totalReceived: 0,
        transactions: 0,
        balance: 0
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon abandon abandon abandon abandon about about abandon abandon about', 
        address: '3CDJNfdWX8m2NwuGUV3nhXHXEeGGgMXoAj', 
        totalReceived: 25.86,
        transactions: 3,
        balance: 0.002 
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon abandon about about abandon abandon abandon abandon abandon about about', 
        address: '1C7656EC7ab88b098EefB751B765464f6d8976F', 
        totalReceived: 0,
        transactions: 12,
        balance: 0.00001
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon about abandon abandon about abandon abandon abandon arrow about about', 
        address: '1cMh228HTC23S8ZsaakH8A8wze1JR5ZsP', 
        totalReceived: 0,
        transactions: 1,
        balance: 0
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon abandon about abandon about abandon abandon abandon abandon abandon abandon about', 
        address: '3CDJNfdWX8m2NwuGUV3nhXHXEeLygM67Aj', 
        totalReceived: 25.86,
        transactions: 3,
        balance: 0.002 
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'about about abandon abandon abandon abandon abandon abandon abandon abandon about about', 
        address: '1C7656EC7ab88b098defB751B76546976d8976F', 
        totalReceived: 0,
        transactions: 123,
        balance: 0.00001
    },
    { 
        currency: Currency.BTC,
        mnemonic: 'abandon about abandon abandon abandon about abandon about abandon arrow about about', 
        address: '1cMh228HTCiwS8Zsa12H8A8wze1JR5ZsP', 
        totalReceived: 0,
        transactions: 1,
        balance: 30
    },
]