import React, { useState } from 'react';
import { Table } from 'antd';
import s from './style.module.css';
import { addressColumn, balanceColumn, totalReceivedColumn, transactionsColumn } from '../TableColumns';
import Pagination from '../Pagination/Pagination';


const columns = [
    {
        title: 'Mnemonic',
        dataIndex: 'mnemonic',
        render: (value) => <span className={s.mnemonic}>{value}</span>
    },
    { ...addressColumn, width: 410 },
    transactionsColumn,
    totalReceivedColumn,
    balanceColumn,
]

export const MnemonicTable = ({ isLoading, list, onPageChange, page, size }) => {
    return <>
    <Table
        columns={columns}
        dataSource={list}
        rowKey='mnemonic'
        className={s.table}
        loading={isLoading}
        pagination={false}
    />
    <div className={s.paginationWrapper}>
        <Pagination
            current={page}
            total={'340282366920938463463374607431768211451'}
            pageSize={size}
            onChange={onPageChange}
            showLessItems
        />
    </div>
    </>
}
