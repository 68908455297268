import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import bn from 'bn.js'
import { MnemonicTable } from '../../components/MnemonicTable/MnemonicTable';
import { useMnemonicList } from '../../hooks/useMnemonicList';
import s from './style.module.css'
import { MnemonicForm } from "../../components/MnemonicForm/MnemonicForm";

export const BrowseMnemonics = () => {
    const isMounted = useRef(false)
    const { mnemonicList, isMnemonicListLoading, getMnemonicList } = useMnemonicList();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        getMnemonicList({
            page: searchParams.get('page'),
            size: searchParams.get('size'),
            currency: searchParams.get('currency'),
            entropy: searchParams.get('entropy'),
        });
        },
        [searchParams.get('page'), searchParams.get('size'), searchParams.get('currency'), searchParams.get('entropy')]
    );

    /**
     *
     * @param {string} page
     * @param {string} size
     */
    const handleChangePage = (page, size) => {
        setSearchParams({
            page,
            size,
            currency: searchParams.get('currency')
        })
    }

    const handleChangeEntropy = entropy => {
        if (!isMounted.current) {
            isMounted.current = true
            return
        }

        const entropyBn = new bn(entropy, 16)
        const page = entropyBn.div(new bn(10, 10)).add(new bn(1)).toString()

        return setSearchParams({
            page,
            size: searchParams.get('size'),
            currency: searchParams.get('currency')
        })
    }

    return (
		<div className={s.wrapper}>
            <MnemonicForm onSubmit={handleChangeEntropy} hasButton={false} />
            <MnemonicTable
                isLoading={isMnemonicListLoading}
                page={searchParams.get('page')}
                size={searchParams.get('size')}
                list={mnemonicList}
                onPageChange={handleChangePage}
            />
        </div>
    )
}
