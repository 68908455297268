import React from 'react';
import { arrayOf, string, number, shape, oneOf, bool } from 'prop-types';
import { Currency, Currency as ECurrency, CurrencyData } from '../../constants';
import { Table } from 'antd';
import s from './style.module.css';
import { addressColumn, balanceColumn, totalReceivedColumn, transactionsColumn } from '../TableColumns';

const columns = [
    {
        title: 'Currency',
        dataIndex: ['currency', 'type'],
        key: 'currency',
        render: (_, { currency, type }) => <div className={s.currencyCellInner}>
            <img src={CurrencyData[currency].icon} className={s.currencyIcon}/>
            {CurrencyData[currency].name}
            {currency === Currency.BTC && <> <span className={s.type}>({type})</span></>}
        </div>,
        width: 170,
    },
    addressColumn,
    transactionsColumn,
    totalReceivedColumn,
    balanceColumn,
  ];

export const WalletList = ({ list, isLoading }) => {
    return <Table
        columns={columns}
        dataSource={list}
        rowKey='address'
        className={s.table}
        loading={isLoading}
        pagination={false}
    />
}

WalletList.propTypes = {
    list: arrayOf(shape({
        currency: oneOf(Object.values(ECurrency)),
        address: string,
        transactions: number,
        balance: number
    })),
    isLoading: bool
}