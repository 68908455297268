import { useMemo, useEffect, useState, useRef } from 'react';
import { Button } from 'antd';
import { generateMnemonic, mnemonicToEntropy, validateMnemonic } from 'bip39';
import styles from './style.module.css';
import { getMnemonicWithReplacedWord, MnemonicLookup } from './MnemonicLookup';

const INITIAL_WORDS = 'abandon abandon abandon abandon abandon abandon abandon abandon abandon abandon abandon about'

export const MnemonicForm = ({ getInitialValue = () => INITIAL_WORDS, onSubmit, hasButton = true }) => {
    const wordsRef = useRef(null)
    const [mnemonic, setMnemonic] = useState(getInitialValue);

    const setNewRandomMnemonic = () => {
        setMnemonic(generateMnemonic());
    }

    /** Search immediately when mnemonic changes */
    useEffect(() => {
        if (mnemonic) {
            onSubmit(mnemonicToEntropy(mnemonic));
        }
    }, [mnemonic]);

    useEffect(() => {
        const handlePasteAnywhere = event => {
            if (!wordsRef.current || !wordsRef.current.contains(event.target)) {
                return;
            }

            const value = event.clipboardData.getData('text')

            if (validateMnemonic(value)) {
                event.target.value = ''
                setMnemonic(value)
            }
        };

        window.addEventListener('paste', handlePasteAnywhere);

        return () => {
            window.removeEventListener('paste', handlePasteAnywhere);
        };
    }, []);

    const words = useMemo(() => mnemonic.split(' '), [mnemonic]);

    const onWordChange = (newWord, index) => {
        setMnemonic(getMnemonicWithReplacedWord(words, newWord, index));
    }


    return <div className={styles.container} ref={wordsRef}>
        {
            words.map((word, index) => (
                <MnemonicLookup words={words} index={index} key={index} onChange={onWordChange} />
            ))
        }
        {
            hasButton && <Button type="primary" onClick={setNewRandomMnemonic}>Random</Button>
        }
    </div>
}
