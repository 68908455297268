import BN from 'bn.js';

export const isInteger = (stringValue) => {
    let res = null;
    try {
        res = new BN(stringValue);
    } catch (e) {
        console.warn(`${stringValue} IS NOT VALID BN`);
    }
    return res !== null;
}