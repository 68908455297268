import { generateMnemonic } from 'bip39'
import { MnemonicForm } from "../../components/MnemonicForm/MnemonicForm";
import { WalletList } from "../../components/WalletList/WalletList";
import { useWalletList } from '../../hooks/useWalletList';
import s from './style.module.css'

export const GenerateWallet = () => {
    const { walletList, isWalletListLoading, getWalletList } = useWalletList();

    return (
		<div className={s.wrapper}>
                <MnemonicForm onSubmit={getWalletList} getInitialValue={() => generateMnemonic()} />
				<WalletList list={walletList} isLoading={isWalletListLoading} />
        </div>
    )
}
