import BN from 'bn.js';

/**
 *
 * @param {BN} offset
 * @param {BN} size
 * @returns
 */
export const generateEntropyPage = (offset, size) => {
    const result = [];
    let base = new BN(offset, 10);
    let inc = new BN(1, 10);

    result.push(base.toArrayLike(Buffer, 'be', 16).toString('hex'));

    for (let i = new BN(1); i.lt(new BN(size)); i = i.add(new BN(1))) {
        base = base.add(inc);
        const mnemonic = base.toArrayLike(Buffer,'be', 16).toString('hex')
        result.push(mnemonic);
    }

    return result;
}

export const generatePageFromEntropy = entropy => {

}
