import s from './styles.module.css'
import { Layout, Typography } from 'antd';
import {
	BrowserRouter,
	Routes,
	Route,
	NavLink,
} from "react-router-dom";
import { GenerateWallet } from "./pages/generate-wallet/GenerateWallet";
import { BrowseMnemonics } from "./pages/browse-mnemonics/BrowseMnemonics";

const { Content, Header } = Layout;

export const App = () => {
    return (
		<BrowserRouter>
			<Layout className='layout'>
				<Header className='header'>
					<nav className='nav'>
					<Typography.Title level={1} className={s.h1}>MNEMONIA</Typography.Title>
						<ul className='ul'>
							<li className='menu-item'>
								<NavLink to='/' className='navlink'>
									Generate wallet
								</NavLink>
							</li>
							<li className='menu-item'>
								<NavLink to='/browse-mnemonics?page=1&size=10&currency=BTC' className='navlink'>
									Browse mnemonics
								</NavLink>

							</li>
						</ul>
					</nav>
				</Header>
				<Content className='content'>
					<div className={s.appContainer}>
							<Routes>
								<Route path='/' element={<GenerateWallet />} />
								<Route path={'/browse-mnemonics'} element={<BrowseMnemonics />} />
							</Routes>
					</div>
				</Content>
			</Layout>
		</BrowserRouter>
    )
}
